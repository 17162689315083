<template>
  <a-modal
    v-model="show"
    title="费用明细"
    :footer="null"
    :width="1800"
    @cancel="handleCancleClick"
  >
    <a-button @click="toImage">导出图片</a-button>
    <div id="imageWrapper" ref="imageWrapper">
      <div style="text-align:center;font-size:40px;font-weight:900;margin-top:20px">
        {{feeData.year}}年{{feeData.month}}月出差记录明细表
      </div>
      <div style="margin-left:20px"><img src="eaton-icon.png" width="100" /></div>
      <div style="margin-top:20px;margin-left:23px;font-size:15px;font-weight:900;">
				<a-row style="margin-bottom:50px;">
				  <a-col :span="6">部门经理:</a-col>
				  <a-col :span="6">财务经理:</a-col>
				  <a-col :span="6">HR经理:</a-col>
				  <a-col :span="6">工厂厂长:</a-col>
				</a-row>
				<a-row style="margin-bottom:50px;">
				  <a-col :span="6">日期</a-col>
				  <a-col :span="6">日期</a-col>
				  <a-col :span="6">日期</a-col>
				  <a-col :span="6">日期</a-col>
				</a-row>
      </div>
      <div style="margin-top:20px;margin-left:23px;font-size:20px;font-weight:900;">总费用：{{feeData.allTotalFee}}</div>
      <a-card title="汇总表" :bordered="false">
        <a-table
          :columns="columns"
          :data-source="feeData.approveAllowanceList"
          :defaultExpandAllRows="true"
          :rowKey="(record) => record.userId"
          :customHeaderRow="customHeaderRow"
          :customRow="customRow"
          :pagination="false"
        >
        </a-table>
      </a-card>
      <a-card title="明细表" :bordered="false">
        <a-table
          :columns="detailColumns"
          :data-source="feeData.allowanceList"
          :defaultExpandAllRows="true"
          :rowKey="(record) => record.allowanceId"
          :customHeaderRow="customHeaderRow"
          :customRow="customRow"
          :pagination="false"
        >
        </a-table>
      </a-card>
    </div>
  </a-modal>
</template>
<script>
import moment from "moment";
// import html2canvas from "html2canvas";
import html2canvas from"@/utils/html2canvas.js"

export default {
  name: "allTotalFeeDetail",
  props: {
    feeVisiable: {
      require: true,
      default: false,
    },
    feeData: {
      require: true,
    },
  },
  computed: {
    show: {
      get: function () {
        return this.feeVisiable;
      },
      set: function () {},
    },
    columns() {
      return [
        {
          title: "员工",
          dataIndex: "username",
          width: 50,
        },
        {
          title: "出差天数",
          dataIndex: "totalDays",
          width: 50,
        },
        {
          title: "费用合计",
          dataIndex: "totalFee",
          width: 50,
        },        
        // {
        //   title: "餐费",
        //   dataIndex: "totalMealFee",
        //   width: 50,
        // },
        // {
        //   title: "住宿费",
        //   dataIndex: "totalHotelFee",
        //   width: 50,
        // },
        {
          title: "餐补",
          dataIndex: "totalMealFee",
          width: 50,
        },
        {
          title: "交通费",
          dataIndex: "totalCarFee",
          width: 50,
        },
        {
          title: "特殊补贴",
          dataIndex: "totalSpecialFee",
          width: 50,
        },
        {
          title: "其他费",
          dataIndex: "totalOtherFee",
          width: 50,
        },
        // {
        //   title: "差旅补贴",
        //   dataIndex: "totalWorkFee",
        //   width: 50,
        // }
      ];
    },
    detailColumns() {
      return [
        {
          title: "员工编号",
          dataIndex: "empno",
          width: 100,
        },
        {
          title: "用户名",
          dataIndex: "username",
          width: 100,
        },
        {
          title: "部门",
          dataIndex: "deptName",
          width: 150,
        },
        {
          title: "行程开始",
          dataIndex: "beginDate",
          width: 150,
          customRender: (text, row, index) => {
            return (
              moment(text).format("YYYY-MM-DD") + " " + row.beginHour + "时"
            );
          },
        },
        {
          title: "行程结束",
          dataIndex: "endDate",
          width: 150,
          customRender: (text, row, index) => {
            return moment(text).format("YYYY-MM-DD") + " " + row.endHour + "时";
          },
        },
        {
          title: "天数",
          dataIndex: "days",
          width: 80,
        },
        // {
        //   title: "工单",
        //   dataIndex: "taskNo",
        //   width: 150,
        // },
        {
          title: "合同号",
          dataIndex: "contractNo",
          width: 150,
        },
        // {
        //   title: "客户名称",
        //   dataIndex: "customer",
        //   width: 150,
        // },
        {
          title: "服务类型",
          dataIndex: "taskTypeValue",
          width: 150,
        },
        // {
        //   title: "早餐补贴",
        //   dataIndex: "breakfastFee",
        //   width: 100,
        // },
        // {
        //   title: "午餐补贴",
        //   dataIndex: "lunchFee",
        //   width: 100,
        // },
        // {
        //   title: "晚餐补贴",
        //   dataIndex: "supperFee",
        //   width: 100,
        // },
        // {
        //   title: "住宿补贴",
        //   dataIndex: "hotelFee",
        //   width: 100,
        // },
        {
          title: "餐补",
          dataIndex: "mealFee",
          width: 100,
        },
        {
          title: "交通费用",
          dataIndex: "carFee",
          width: 100,
        },
        {
          title: "特殊补贴",
          dataIndex: "specialFee",
          width: 100,
        },
        {
          title: "其他费用",
          dataIndex: "otherFee",
          width: 100,
        },
        {
          title: "差旅补贴",
          dataIndex: "workFee",
          width: 100,
        },
      ]
    },
  },
  data() {
    return {};
  },
  methods: {
    toImage() {
      html2canvas(this.$refs.imageWrapper, {
        allowTaint: false,
        useCORS: true,
        background: "#ffffff", // 一定要添加背景颜色，否则出来的图片，背景全部都是透明的
        scale: 2, // 处理模糊问题
        dpi: 300, // 处理模糊问题
      }).then((canvas) => {
        let dataURL = canvas.toDataURL("image/png");
        console.log(dataURL, "dataURL");
        let a = document.createElement("a");
        let event = new MouseEvent("click");
        a.download = "user_allowance_batch_" + this.feeData.batchId;;
        a.href = dataURL;
        a.dispatchEvent(event);
      });
    },
    handleCancleClick() {
      this.$emit("close");
    },
    customHeaderRow(record,index) {
      return {style:{'font-size':'16px','font-weight':900}};
    },
    customRow(record,index){
      return {style:{'font-size':'15px','font-weight':900}};
    }
  },
};
</script>
<style lang="less">
.rowClass{
  font-weight:900;
}
</style>